<template>
    <el-dialog :title="goods_title_id ? '修改人员' : '添加人员'" :visible.sync="confitionDialog" append-to-body custom-class='class-dialog' width="700px" @close="onCancel" :close-on-click-modal='false' :close-on-press-escape='false'>
        <div class="dialog-inside videoDialog">
            <div class="sunbox">
                <div class="sunbox-text">人员部门</div>
                <div class="sunbox-content" style="width:500px">
                    <el-select v-model="department" placeholder="请选择">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
            </div>

            <div class="sunbox">
                <div class="sunbox-text">真实姓名</div>
                <div class="sunbox-content" style="width:100px">
                    <el-input v-model="real_name" placeholder="请输入真实姓名"></el-input>
                </div>
            </div>
            <div class="sunbox">
                <div class="sunbox-text">别名</div>
                <div class="sunbox-content" style="width:100px">
                    <el-input v-model="alias" placeholder="请输入别名"></el-input>
                </div>
            </div>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button type="default" @click="onCancel" class="button-Dialog">取 消</el-button>
            <el-button type="primary" @click="onSubmit" class="button-Dialog">提 交</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            confitionDialog: false,
            TypeList: [],//分类列表
            goods_title_id: '',//文案id
            goods_name: '',//文案名称
            video_type: '',//分类id
            options: [{
                value: 1,
                label: '运营'
            }, {
                value: 2,
                label: '剪辑'
            }, {
                value: 3,
                label: '拍摄'
            }, {
                value: 4,
                label: '商务'
            }],
            department: '',  //部门
            real_name: "",  //真实姓名
            alias: ""    //别名
        };
    },

    mounted() {
    },

    methods: {
        //分类列表
        // ongetVideoTypeList(){
        //     this.$service.get(this.$api.getVideoTypeList,{}, (res)=> {
        //         if(res.code == '200'){
        //             this.TypeList = res.data
        //         }
        //     })
        // },
        //提交
        onSubmit() {
            if (this.department == '') { this.$message({ message: "请选择部门", type: "warning" }); return; }
            if (this.real_name == '') { this.$message({ message: "请填写真实姓名", type: "warning" }); return; }
            if (this.alias == '') { this.$message({ message: "请填写别名", type: "warning" }); return; }
            if (this.goods_title_id == "") {
                // var adv_ids = this.goods_name.split('\n');
                // var filtered = adv_ids.filter(function (el) {
                //     return el != "";
                // });
                var param = {
                    department: this.department,  //部门
                    real_name: this.real_name,  //真实姓名
                    alias: this.alias,  //别名
                }
                this.$service.post(this.$api.addpersonnel, param, (res) => {
                    if (res.code == '200') {
                        this.$message({ message: '添加人员成功', type: 'success' })
                        this.$parent.ongetProduct()
                        this.onCancel()
                    }
                })
            } else {
                var param = {
                       department: this.department,  //部门
                    real_name: this.real_name,  //真实姓名
                    alias: this.alias,  //别名
                }
                param.id = this.goods_title_id;
                this.$service.post(this.$api.updatepersonnel, param, (res) => {
                    if (res.code == '200') {
                        this.$message({ message: '修改人员成功', type: 'success' })
                        this.$parent.ongetProduct()
                        this.onCancel()
                    }
                })
            }

        },
        //取消
        onCancel() {
            this.confitionDialog = false;
            this.TypeList = [];//分类列表
            this.department = ""; //部门
            this.real_name = "";  //真实姓名
            this.alias = "";  //别名
            this.goods_title_id = '';//id
        },
        //添加分类弹窗
        onDialog(item) {
            if (item) {
                this.goods_title_id = item.id;//账户ID
                this.real_name=item.real_name;
                this.department=item.department;
                this.alias=item.alias;
            }
            this.confitionDialog = true
        },
    },



    // methods: {
    // 测试用
    // onceshi(){
    // 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
    // 		console.log(123)
    // 		if(res.code == '200'){

    // 		}
    // 	})
    // },
    // }
};
</script>

<style lang="scss" scoped>
.classifyList {
    .group-table {
        .TableList {
            .el-tag {
                cursor: pointer;
                margin-bottom: 10px;
                margin-right: 20px;
            }
        }
    }
}
</style>
<style lang="scss">
</style>